export const PRODUCT_DATA = "PRODUCT_DATA";
export const ADD_ITEM = "ADD_ITEM";
export const REACTION_TIME= "REACTION_TIME"
export const REACTION_ADD= "REACTION_ADD"
export const SEQUENCE= "SEQUENCE"
export const SEQUENCE_ADD= "SEQUENCE_ADD"
export const NUMBER_MEMORY= "NUMBER_MEMORY"
export const NUMBER_MEMORY_ADD= "NUMBER_MEMORY_ADD"
export const VERBAL_MEMORY= "VERBAL_MEMORY"
export const VERBAL_MEMORY_ADD= "VERBAL_MEMORY_ADD"
export const VISUAL_MEMORY= "VISUAL_MEMORY"
export const VISUAL_MEMORY_ADD= "VISUAL_MEMORY_ADD"
export const SCORE_DATA= "SCORE_DATA"
export const CHIMP_TEST= "CHIMP_TEST"
export const REACTION_TIME_RANK = "REACTION_TIME_RANK"





